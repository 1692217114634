<template>
    <el-container>
        <el-main class="main-box">
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>机动人员列表</span>
                        </el-col>
                        <el-upload
                                class="upload"
                                name="file"
                                accept=".xlsx,.xls"
                                :multiple="false"
                                :action="action"
                                :headers="headers"
                                :show-file-list="false"
                                :on-success="onSuccess"
                                :on-error="onError"
                        >
                            <el-button size="small" type="primary">上传EXCEL</el-button>
                        </el-upload>
                        <el-button @click="downLoadTemplate">下载模板</el-button>
                    </el-row>
                </div>
                <div class="text item">
                    <el-row :gutter="20">
                        <el-input
                                class="search"
                                v-model="queryParams.mobileId"
                                placeholder="请输入ID"
                                @input="onInput"
                        >
                            <i slot="suffix" class="el-input__icon el-icon-search" @click="onHandle"></i>
                        </el-input>
                        <el-select v-model="queryParams.roleId" class="search" placeholder="角色" clearable
                                   @change="onHandle">
                            <el-option
                                    v-for="item in roleList"
                                    :key="item.id"
                                    :label="item.description"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <el-select v-model="queryParams.groupName" class="search" placeholder="分组" clearable
                                   @change="onHandle">
                            <el-option
                                    v-for="(item,index) in groupList"
                                    :key="index"
                                    :label="item.group_name"
                                    :value="item.group_id"
                            >
                            </el-option>
                        </el-select>
                    </el-row>

                    <el-table
                            stripe
                            :data="tableList"
                    >
                        <el-table-column prop="id" label="ID" align="center"/>
                        <el-table-column prop="mobileName" label="姓名" align="center"/>
                        <el-table-column prop="mobilePhone" label="手机号" align="center"/>
                        <el-table-column prop="groupName" label="组名" align="center"/>
                        <el-table-column prop="mobileRole" label="角色" align="center"/>
                        <el-table-column prop="statusValid" label="状态" align="center">
                            <template slot-scope="scope">
                                <el-switch
                                        v-model="scope.row.statusValid"
                                        active-text="启用"
                                        inactive-text="禁用"
                                        :active-value="0"
                                        :inactive-value="1"
                                        @change="onSwitchChange(scope.row)"
                                >
                                </el-switch>
                            </template>
                        </el-table-column>

                        <el-table-column prop="mobileGroupName" label="组类型" align="center">
                            <template slot-scope="scope">
                                <el-button
                                        v-if="scope.row.roleId==5"
                                        size="small"
                                        type="text"
                                        style="color: #563279"
                                        @click="transferHandle(scope.row)"
                                >转让组长
                                </el-button>
                                <el-button
                                        v-if="scope.row.roleId!=5"
                                        size="small"
                                        type="text"
                                        style="color: #563279"
                                        @click="changeGroupHandle(scope.row)"
                                >机动换组
                                </el-button>
                                <el-button
                                        size="small"
                                        type="text"
                                        style="color: #ee0a24"
                                        @click="deleteHandle(scope.row)"
                                >删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页器 -->
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-size="queryParams.pageSize"
                            :page-sizes="[10, 20, 30, 40]"
                            :current-page="queryParams.pageNum"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </el-card>
        </el-main>

        <!--转让组长弹框-->
        <el-dialog
                width="520px"
                title="转让组长"
                :visible.sync="dialogVisible"
                :before-close="onCancel"
                append-to-body>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
                <el-form-item label="转让组长Id：" prop="leaderId">
                    <el-input v-model="ruleForm.leaderId" placeholder="请输入接受组长Id" disabled></el-input>
                </el-form-item>
                <el-form-item label="接受组长Id：" prop="transferId">
                    <el-input v-model="ruleForm.transferId" placeholder="请输入接受组长Id"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onCancel">取 消</el-button>
                <el-button type="primary" @click="onCommit">确认</el-button>
            </div>
        </el-dialog>
        <!--机动换组弹框-->
        <el-dialog
                width="520px"
                title="机动换组"
                :visible.sync="dialogVisibleTrans"
                :before-close="onCancelTrans"
                append-to-body>
            <el-form :model="transForm" :rules="transRules" ref="transForm" label-width="120px">
                <el-form-item label="机动Id：" prop="transferId">
                    <el-input v-model="transForm.transferId" placeholder="请输入机动Id" disabled></el-input>
                </el-form-item>
                <el-form-item label="分组：" prop="groupName">
                    <el-select v-model="transForm.groupName" placeholder="分组" clearable style="width: 100%;">
                        <el-option
                                v-for="(item,index) in groupList"
                                :key="index"
                                :label="item.group_name"
                                :value="item.group_id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onCancelTrans">取 消</el-button>
                <el-button type="primary" @click="onCommitTrans">确认</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
    import {SERVER_errorURL} from '@/config/server'
    import {
        getMobileList,
        getRoleAndGroupData,
        deleteMobile,
        importExcel,
        downLoadExcelTemplate,
        transferLeader,
        transferGroup,
        updateMobileValidStatus
    } from "r/jiDong/engineDriven.js";

    export default {
        name: "EngineDriven",
        data() {
            return {
                isSelect: true,// true-待处理 false-已处理
                tableList: [],
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    roleId: null,
                    mobileId: null,
                    groupName: null
                },
                total: 0,
                roleList: [],
                groupList: [],
                // 转让组长数据
                ruleForm: {
                    leaderId: null,
                    transferId: null
                },
                rules: {
                    leaderId: [
                        {required: true, message: '请输入转让组长Id', trigger: 'blur'},
                    ],
                    transferId: [
                        {required: true, message: '请输入接受组长Id', trigger: 'blur'},
                    ],
                },
                dialogVisible: false,// 控制转让组长弹框的显示与隐藏

                // 换组数据
                transForm: {
                    transferId: null,
                    groupName: null
                },
                transRules: {
                    transferId: [
                        {required: true, message: '请输入机动Id', trigger: 'blur'},
                    ],
                    groupName: [
                        {required: true, message: '请选择分组', trigger: 'change'},
                    ],
                },
                dialogVisibleTrans: false,// 控制换组弹框的显示与隐藏
                action: SERVER_errorURL + 'eventProblem/importExcel',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('jiDong-token'),
                }
            }
        },
        created() {
            this.getRoleAndGroupList();
            this.getList();
        },
        methods: {
            onSwitchChange(row) {
                console.log(row)
                let txt;
                if (row.statusValid == 0) {
                    txt = '启用';
                } else {
                    txt = '禁用';
                }
                this.$confirm(`确认${txt}该机动人员？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let obj = {
                        id: row.id,
                        status: row.statusValid
                    }
                    updateMobileValidStatus(obj)
                        .then(res => {
                            console.log(res)
                            if(res.data.code!=200){
                                row.statusValid = row.statusValid == 0 ? 1 : 0;
                                return this.$message.error(res.data.message);
                            }
                            this.$message.success(res.data.message);
                        })
                        .catch(err => {
                            row.statusValid = row.statusValid == 0 ? 1 : 0;
                            console.log(err);
                        })
                }).catch(() => {
                    row.statusValid = row.statusValid == 0 ? 1 : 0;
                    this.$message.info(`已取消${txt}`);
                });
            },
            // 机动换组-确认
            onCommitTrans() {
                this.$refs['transForm'].validate((valid) => {
                    if (!valid) return;
                    transferGroup(this.transForm)
                        .then(res => {
                            console.log(res)
                            if (res.data.code != 200) {
                                return this.$message.error(res.data.message);
                            }
                            this.$message.success(res.data.message);
                            this.$refs['transForm'].resetFields();
                            this.dialogVisibleTrans = false;
                            this.getList();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                });
            },
            // 机动换组-取消
            onCancelTrans() {
                this.$refs['transForm'].resetFields();
                this.dialogVisibleTrans = false;
            },
            // 机动换组
            changeGroupHandle(row) {
                this.transForm.transferId = row.id;
                this.dialogVisibleTrans = true;
            },
            // 转让组长-确认
            onCommit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (!valid) return;
                    transferLeader(this.ruleForm)
                        .then(res => {
                            console.log(res)
                            if (res.data.code != 200) {
                                return this.$message.error(res.data.message);
                            }
                            this.$message.success(res.data.message);
                            this.$refs['ruleForm'].resetFields();
                            this.dialogVisible = false;
                            this.getList();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                });
            },
            // 转让组长-取消
            onCancel() {
                this.$refs['ruleForm'].resetFields();
                this.dialogVisible = false;
            },
            // 转让组长
            transferHandle(row) {
                console.log(row)
                this.ruleForm.leaderId = row.id;
                this.dialogVisible = true;
            },
            onSuccess(response, file, fileList) {
                if (response.code != 200) {
                    return this.$message.error(response.message)
                }
                this.$message.success(response.message)
            },
            onError(err, file, fileList, ss) {
                this.$message.error(err.message)
            },
            // 下载模板
            downLoadTemplate() {
                window.open(SERVER_errorURL + 'eventProblem/downLoadExcelTemplate', "_self");
            },
            // 删除
            deleteHandle(row) {
                this.$confirm('此操作将永久删除该机动人员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteMobile({
                        mobileId: row.id
                    })
                        .then(res => {
                            console.log(res)
                            if (res.data.code != 200) {
                                return this.$message.error(res.data.message);
                            }
                            this.$message.success(res.data.message);
                            this.getList();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            onHandle() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            onInput(value) {
                this.queryParams.mobileId = value.replace(/[^\d]/g, '');
            },
            // 获取事件类型列表
            getRoleAndGroupList() {
                getRoleAndGroupData({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.roleList = res.data.data.teacherRoles;
                        this.groupList = res.data.data.groupNames;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取列表
            getList() {
                getMobileList(this.queryParams)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.tableList = res.data.data.records;
                        this.total = res.data.data.total;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 控制每页显示几条数据
            handleSizeChange(newSize) {
                this.queryParams.pageSize = newSize;
                this.getList();
            },
            // 控制显示第几页
            handleCurrentChange(newNum) {
                this.queryParams.pageNum = newNum;
                this.getList();
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/scss/jiDongCommon";
    @import "a/scss/table";
    @import "a/scss/page";

    ::v-deep .el-table__header-wrapper {
        height: auto !important;
    }
</style>

