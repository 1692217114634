import { request } from "./network";

// 获取机动人员列表
export function getMobileList(params) {
    return request({
        method: "get",
        url: "/eventProblem/getMobileList",
        params,
    });
}

// 获取角色列表和分组列表
export function getRoleAndGroupData(params) {
    return request({
        method: "get",
        url: "/eventProblem/getRoleAndGroupData",
        params,
    });
}

// 删除机动人员
export function deleteMobile(params) {
    return request({
        method: "delete",
        url: "/eventProblem/deleteMobile",
        params,
    });
}

// 下载模板
export function downLoadExcelTemplate(params) {
    return request({
        method: "get",
        url: "/eventProblem/downLoadExcelTemplate",
        params,
    });
}

// 上传EXCEL
export function importExcel(data) {
    return request({
        method: "post",
        url: "/eventProblem/importExcel",
        data,
    });
}

// 转让组长
export function transferLeader(params) {
    return request({
        method: "put",
        url: "/eventProblem/transferLeader",
        params,
    });
}

// 机动换组
export function transferGroup(params) {
    return request({
        method: "put",
        url: "/eventProblem/transferGroup",
        params,
    });
}

// 设置机动状态  0-启用 1-禁用
export function updateMobileValidStatus(params) {
    return request({
        method: "put",
        url: "/eventProblem/updateMobileValidStatus",
        params,
    });
}
